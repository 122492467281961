import React from 'react';
// material import
import { Button, Box, Card, CircularProgress, CardContent, FormHelperText, IconButton, Select, Stack, Typography, TextField, Paper, MenuItem, Tooltip } from '@material-ui/core';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import { Filter, FileCopy, DeleteOutline, CloseOutlined, Drafts } from '@material-ui/icons';

// other npm imports
import { FileUploader } from "react-drag-drop-files";
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import { useSnackbar } from 'notistack5';
// import { useScreenshot, createFileName } from "use-react-screenshot";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
// AWS IMPORTS
import AWS from 'aws-sdk';
import { S3_BUCKET, SECRET_ACCESS_KEY, ACCESS_KEY, REGION, URLFront } from "../../../backend";


// context imports
import { SettingsContext } from '../../../contexts/SettingsContext';

// other component imports
import { MIconButton } from '../../../components/@material-extend';

// API imports
import { getEcpValue } from '../../../_apis_/authApi.js/staffApi'
import { createTicketApi, addTicketAttachmentApi } from '../../../_apis_/authApi.js/help_support_api';

// utilities import
import { trainingRequiredList, trainingTimeList } from "../../../utils/filterUtilities";


const ticketTypeList = [
    { id: '10', name: 'Billing' },
    { id: '20', name: 'Training Appointment' },
    { id: '30', name: 'General Feedback' },
    { id: '40', name: 'Technical Support' },
]



export default function HelpSupportPopUpForm() {
    const [aKey, setAKey] = React.useState('');
    const [secKey, setSecKey] = React.useState('');
    const loggedUserData = JSON.parse(window.localStorage.getItem('userData'));
    const ref = React.useRef(null);
    // const [image, takeScreenShot] = useScreenshot({
    //     type: "image/jpeg",
    //     quality: 1.0
    // });
    // const download = (image, { name = "img", extension = "jpg" } = {}) => {
    //     const a = document.createElement("a");
    //     a.href = image;
    //     a.download = createFileName(extension, name);
    //     a.click();
    // };

    // const downloadScreenshot = () => takeScreenShot(reference?.current).then(download);
    // context states
    const { showHelpSupportPopUp, setShowHelpSupportPopUp, setColor, themeMode } = React.useContext(SettingsContext)
    // react states
    const [file, setFile] = React.useState(null);
    const fileTypes = ["JPEG", "PNG", "GIF", "MP3", "CSV", "PDF", "MKV", "MP4", "AIFF", "WAV"];
    const [priority, setPriority] = React.useState('10');
    const [ticketType, setTicketType] = React.useState('30');
    const [trainingRequired, setTrainingRequired] = React.useState('10');
    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [loadingScore, setLoadingScore] = React.useState(false);
    const [fileProgress, setFileProgress] = React.useState();

    // SNACKBAR STATES
    // snackbar
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    // AWS STATES
    AWS.config.update({
        region: REGION,
        accessKeyId: aKey,
        secretAccessKey: secKey,
    })

    const myBucket = new AWS.S3({
        apiVersion: '2006-03-01'
    })

    const fetchEcpData = async () => {
        const { data } = await getEcpValue();
        setAKey(atob(data?.access));
        setSecKey(atob(data?.secret));
        // console.log(atob(data?.access), atob(data?.secret));
    }

    React.useEffect(() => {
        fetchEcpData();
    }, [])

    const uploadNsave = async (id, ticketId) => {
        setLoadingScore(true);
        // const time = moment().format('hh:mm:ss a');
        try {
            if (id < Object.keys(file).length) {
                myBucket.putObject(
                    {

                        Body: file[id],
                        Key: `media/ticket_attachment/${ticketId}_${file[id]?.name}`,
                        Bucket: S3_BUCKET,
                        ContentType: file[id]?.type
                    }
                )
                    .on('httpUploadProgress', (evt) => {
                        setFileProgress(`Uploading file ${id + 1} 0f ${Object.keys(file).length} (${Math.round((evt.loaded / evt.total) * 100)})%`)
                    }).on('success', (evt) => {
                        uploadNsave(id + 1, ticketId)
                    }).send((err) => {
                        if (err) console.log(err)
                    })
            } else {
                const keyArray = [];
                Object.entries(file).map(item =>
                    keyArray.push({ file_path: `media/ticket_attachment/${ticketId}_${item[1]?.name}`, file_size: Number(`${item[1].size}`), file_name: `${item[1].name}` })
                );
                const fileSubmitObj = {
                    "support_ticket": ticketId,
                    "attachments": keyArray,
                }
                const res = await addTicketAttachmentApi(fileSubmitObj);
                setLoadingScore(false);
                setIsSubmitted(true);
                enqueueSnackbar('Ticket Raised Successfully', {
                    variant: 'success',
                    action: (key) => (

                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    )
                });

            }
        } catch (err) {
            console.log(err);
            enqueueSnackbar(err?.response?.data?.message, {
                variant: 'error',
                action: (key) => (
                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </MIconButton>
                )

            });
            setLoadingScore(false);

        }

    }

    // formik 
    const formik = useFormik({
        initialValues: { subjectValue: '', descriptionValue: '', ccEmail: '', priorityValue: '10', ticketType: '', invoiceId: '', date1: moment().format('YYYY-MM-DD'), time1: '10', date2: moment().format('YYYY-MM-DD'), time2: '10', problemUrl: '', resultId: '', scorecardId: '' },
        validationSchema: Yup.object().shape({
            ccEmail: Yup.string().matches(/^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*,{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/gm, "email must be a valid email"),
            subjectValue: Yup.string().required('Required Field*'),
            descriptionValue: Yup.string().required('Required Field*'),
            invoiceId: ticketType === '10' ? Yup.string().required('Required Field*') : Yup.string(),
            problemUrl: ticketType === '40' ? Yup.string().required('*Required Field') : Yup.string(),
            // resultId: ticketType === '40' ? Yup.string().required('*Required Field') : Yup.string(),
            // scorecardId: ticketType === '40' ? Yup.string().required('*Required Field') : Yup.string(),
        }),
        onSubmit: async (values) => {
            try {
                const submitObj = {
                    "ticket_subject": values.subjectValue,
                    "ticket_description": `<p>${values.descriptionValue}</p>`,
                    "ticket_status": 20,
                    "ticket_priority": priority,
                    "ticket_cc_emails": values.ccEmail,
                    "ticket_type": ticketType,
                }
                if (ticketType === '10') {
                    submitObj.other_details = {
                        'invoice_id': values.invoiceId
                    }
                }
                if (ticketType === '20') {
                    submitObj.other_details = {
                        training_type: trainingRequired,
                        available_date_1: values.date1,
                        available_time_1: values.time1,
                        available_date_2: values.date2,
                        available_time_2: values.time2,
                    }
                }
                if (ticketType === '40') {
                    submitObj.other_details = {
                        'problem_url': values.problemUrl,
                        'result_id': values.resultId,
                        'scorecard_id': values.scorecardId
                    }
                }
                const { data } = await createTicketApi(submitObj);
                if (file) {
                    return uploadNsave(0, data?.id);
                }
                enqueueSnackbar('Ticket Raised Successfully', {
                    variant: 'success',
                    action: (key) => (

                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    )
                });
                setIsSubmitted(true)
            } catch (err) {
                enqueueSnackbar('Ticket Not Raised', {
                    variant: 'error',
                    action: (key) => (

                        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                            <Icon icon={closeFill} />
                        </MIconButton>
                    )
                });
            }

        }
    })

    // remove file
    const removeFile = (id) => {
        const newCar = Object.keys(file).reduce((object, key) => {
            if (key !== id) {
                object[key] = file[key]
            }
            return object
        }, {})
        const newObj = {}
        Object.entries(newCar).map((item, index) => {
            const [data1, data2] = item;
            return newObj[index] = data2;
        }
        )
        setFile(newObj);
    }
    // handle change
    const handleChangeFile = (data) => {
        if (Object?.keys(data)?.length < 5) {
            if (file) {
                const len = Object?.keys(file)?.length;
                let n = len;
                if (len > 0 && len < 5) {
                    const newFile = {};
                    /* eslint-disable */
                    // x[len] = data[0];
                    Object.entries(data).map((item, index) => {
                        /* eslint-disable */
                        newFile[n] = data[index];
                        n += 1;
                    })
                    const noOfFile = { ...file, ...newFile }
                    if (Object?.keys(noOfFile)?.length < 5) {
                        return setFile({ ...file, ...newFile });
                    }
                    if (Object?.keys(noOfFile)?.length > 5) {
                        enqueueSnackbar('Maximum 5 files', {
                            variant: 'error',
                            action: (key) => (

                                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                                    <Icon icon={closeFill} />
                                </MIconButton>
                            )
                        });
                        return setFile(file);
                    }

                }

                return setFile(data);
            }

            return setFile(data);
        }
        else {
            enqueueSnackbar('Maximum 5 files', {
                variant: 'error',
                action: (key) => (

                    <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                        <Icon icon={closeFill} />
                    </MIconButton>
                )
            });
        }
    };

    const handleClose = () => {
        formik.resetForm();
        setTicketType('30')
        setShowHelpSupportPopUp(false);
        setFile(null)
        setIsSubmitted(false);
        setFileProgress();
        setLoadingScore(false);
    }



    const handlePriority = (e) => {
        setPriority(e.target.value);
    }

    const handleTicketType = (e) => {
        formik.setTouched({}, false); formik.setErrors({}, false);
        setTicketType(e.target.value);
        formik.resetForm();

    }


    return (
        <>
            {showHelpSupportPopUp && <Box ref={ref} sx={{ width: { xs: '300px', lg: '350px', newLg: '420px', xl: '500px', qhd: '595px' }, height: { lg: '400px', newLg: '510px', xl: '685px', qhd: '855px' }, position: 'fixed', right: '0%', top: '30%', zIndex: 5 }}>
                <Card elevation={5} sx={{ height: { xs: '350px', lg: '400px', newLg: '510px', xl: '685px', qhd: '855px' }, width: '100%', position: 'relative', background: theme => `linear-gradient(0deg,${theme.palette.mode === 'light' ? theme.palette.background.paper : '#636e72'} 70%,${themeMode === 'light' ? setColor?.main : setColor?.dark} 30%)`, borderRadius: 2, border: '1px solid grey', px: 2, pb: 1, pt: 4, overflowY: 'auto', '&::-webkit-scrollbar': { borderRadius: 5, width: '8px' }, '&::-webkit-scrollbar-track': { borderTopRightRadius: '50px', borderBottomRightRadius: '50px', backgroundColor: '#dcdde1', overflow: 'hidden' }, '&::-webkit-scrollbar-thumb': { backgroundColor: '#95a5a6', borderRadius: 5 } }}>

                    <IconButton size='small' sx={{ '&:hover': { backgroundColor: theme => theme.palette.background.paper }, backgroundColor: theme => theme.palette.background.paper, position: 'absolute', right: '2px', top: '5px', width: '20px', height: '20px' }} onClick={() => handleClose()}><CloseOutlined fontSize='small' /></IconButton>

                    <Typography sx={{ fontWeight: 'bold', fontSize: '16px', color: 'white', mb: 2 }}>
                        Welcome To Support
                    </Typography>
                    {(!isSubmitted && !loadingScore) && <Box component={Paper} elevation={5} sx={{ width: '100%', margin: '0 auto', backgroundColor: theme => theme.palette.background.paper, borderRadius: 2, p: 1 }}>
                        <TextField label='Name' disabled value={`${loggedUserData?.firstName} ${loggedUserData?.lastName}`} size='small' sx={{ width: '100%', my: 1, fontSize: '14px' }} />
                        <TextField label='Ticket Type' value={ticketType} onChange={handleTicketType} select size='small' sx={{ width: '100%', my: 1 }} >
                            {ticketTypeList?.map((row, index) => <MenuItem key={index} value={row?.id}>{row?.name}</MenuItem>)}

                        </TextField>

                        <TextField label='CC Email' value={formik.values.ccEmail} name='ccEmail' {...formik.getFieldProps('ccEmail')} size='small' sx={{ width: '100%', mt: 1 }} />
                        {!(formik.errors.ccEmail && formik.touched.ccEmail) && <FormHelperText sx={{ mb: 1, pl: 1 }}>*Multiple emails should be seperated by comma</FormHelperText>}
                        {(formik.errors.ccEmail && formik.touched.ccEmail) && <Typography sx={{ fontSize: '12px', color: 'red', pl: 1 }}>{formik.errors.ccEmail}</Typography>}


                        <TextField label='Subject*' value={formik.values.subjectValue} {...formik.getFieldProps('subjectValue')} name='subjectValue' size='small' sx={{ width: '100%', my: 1 }} />
                        {(formik.errors.subjectValue && formik.touched.subjectValue) && <Typography sx={{ fontSize: '12px', color: 'red', pl: 1 }}>{formik.errors.subjectValue}</Typography>}

                        {ticketType === '10' && <> <TextField label='Invoice Id*' value={formik.values.invoiceId} {...formik.getFieldProps('invoiceId')} name='invoiceId' size='small' sx={{ width: '100%', my: 1 }} />
                            {(formik.errors.invoiceId && formik.touched.invoiceId) && <Typography sx={{ fontSize: '12px', color: 'red', pl: 1 }}>{formik.errors.invoiceId}</Typography>}
                        </>
                        }

                        {ticketType === '20' && <>
                            <TextField label='Training Required' value={trainingRequired} onChange={(e) => setTrainingRequired(e.target.value)} select size='small' sx={{ width: '100%', my: 1 }} >
                                {trainingRequiredList?.map((row, index) => <MenuItem key={index} value={row?.id}>{row?.name}</MenuItem>)}
                            </TextField>
                            <>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label='Available Date 1'
                                        minDate={moment().format('YYYY-MM-DD')}
                                        inputFormat="yyyy/MM/dd"
                                        name="date1"
                                        value={formik.values.date1}
                                        onChange={(newValue) => {
                                            if (moment(newValue, "YYYY-MM-DD").isBefore(moment().format('YYYY-MM-DD'))) {
                                                return formik.setFieldValue('date1', moment().format('YYYY-MM-DD'))
                                            }
                                            formik.setFieldValue('date1', moment(newValue).format('YYYY-MM-DD'))

                                        }}

                                        renderInput={(params) => <TextField label='Available Date 1' sx={{ width: '100%', my: 1 }} size='small' fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                                <TextField label='Available Time 1' value={formik.values.time1} onChange={(e) => formik.setFieldValue('time1', e.target.value)} select size='small' sx={{ width: '100%', my: 1 }} >
                                    {trainingTimeList?.map((row, index) => <MenuItem key={index} value={row?.id}>{row?.name}</MenuItem>)}
                                </TextField>

                            </>
                            <>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DesktopDatePicker
                                        label='Available Date 2'
                                        minDate={moment().format('YYYY-MM-DD')}
                                        inputFormat="yyyy/MM/dd"
                                        name="date1"
                                        value={formik.values.date2}
                                        onChange={(newValue) => {
                                            if (moment(newValue, "YYYY-MM-DD").isBefore(moment().format('YYYY-MM-DD'))) {
                                                return formik.setFieldValue('date2', moment().format('YYYY-MM-DD'))
                                            }
                                            formik.setFieldValue('date2', moment(newValue).format('YYYY-MM-DD'))

                                        }}

                                        renderInput={(params) => <TextField label='Available Date 2' sx={{ width: '100%', my: 1 }} size='small' fullWidth {...params} />}
                                    />
                                </LocalizationProvider>
                                <TextField label='Available Time 2' value={formik.values.time2} onChange={(e) => formik.setFieldValue('time2', e.target.value)} select size='small' sx={{ width: '100%', my: 1 }} >
                                    {trainingTimeList?.map((row, index) => <MenuItem key={index} value={row?.id}>{row?.name}</MenuItem>)}
                                </TextField>

                            </>
                        </>}

                        {ticketType === '40' && <>
                            <> <TextField label='Problem URL*' value={formik.values.problemUrl} {...formik.getFieldProps('problemUrl')} name='problemUrl' size='small' sx={{ width: '100%', my: 1 }} />
                                {(formik.errors.problemUrl && formik.touched.problemUrl) && <Typography sx={{ fontSize: '12px', color: 'red', pl: 1 }}>{formik.errors.problemUrl}</Typography>}
                            </>
                            <> <TextField label='Result Id*' value={formik.values.resultId} {...formik.getFieldProps('resultId')} name='resultId' size='small' sx={{ width: '100%', my: 1 }} />
                                {(formik.errors.resultId && formik.touched.resultId) && <Typography sx={{ fontSize: '12px', color: 'red', pl: 1 }}>{formik.errors.resultId}</Typography>}
                            </>
                            <> <TextField label='Scorecard Id*' value={formik.values.scorecardId} {...formik.getFieldProps('scorecardId')} name='scorecardId' size='small' sx={{ width: '100%', my: 1 }} />
                                {(formik.errors.scorecardId && formik.touched.scorecardId) && <Typography sx={{ fontSize: '12px', color: 'red', pl: 1 }}>{formik.errors.scorecardId}</Typography>}
                            </>
                        </>
                        }


                        <TextField label='Description*' name='descriptionValue' value={formik.values.descriptionValue} {...formik.getFieldProps('descriptionValue')} multiline rows={4} size='small' sx={{ width: '100%', my: 1, fontSize: '14px' }} />
                        {(formik.errors.descriptionValue && formik.touched.descriptionValue) && <Typography sx={{ fontSize: '12px', color: 'red', pl: 1 }}>{formik.errors.descriptionValue}</Typography>}
                        <TextField label='Priority' value={priority} onChange={handlePriority} select size='small' sx={{ width: '100%', my: 1 }} >
                            <MenuItem value='10'>Low</MenuItem>
                            <MenuItem value='20'>Medium</MenuItem>
                            <MenuItem value='30'>High</MenuItem>
                        </TextField>
                        {/* <Button variant='outlined' onClick={() => downloadScreenshot()} fullWidth sx={{ my: 1 }} startIcon={<Filter />}>Take Screenshot</Button> */}
                        <Box sx={{ p: 1, overflowX: "auto", borderRadius: 1, my: 1, border: "1px dashed #95afc0", minHeight: "90px", backgroundColor: themeMode === 'light' ? '#f5f6fa' : '#636e72' }}>
                            <Box display="flex" flexDirection="column" alignItems="center">
                                <FileUploader
                                    multiple
                                    handleChange={handleChangeFile}
                                    name="file"
                                    types={fileTypes}
                                >
                                    <Typography variant="body2" sx={{ fontSize: "12px", mt: 2, mb: 1, fontWeight: 'bold' }}>Drop File to Attach (max 5 files)</Typography>
                                    <Tooltip title="Select File"><FileCopy sx={{ fontSize: "50px", cursor: "pointer", color: "blue", ml: 5, my: 1 }} /></Tooltip>
                                </FileUploader>
                            </Box>
                            <Stack direction="row" sx={{ my: 1, p: 1, width: "100%", overflowX: "auto" }}>
                                {file ? Object.entries(file).map((item, index) =>
                                    <Tooltip title={`File Name: ${item[1]?.name}, File Size: ${(item[1].size / 1048576).toFixed(3)} MB`}>
                                        <Box sx={{ mx: 1, border: '1px solid #ced6e0', borderRadius: 1, position: 'relative' }}>

                                            <FileCopy sx={{ fontSize: "45px", cursor: "pointer", color: "blue", ml: 1, my: 1 }} />
                                            <Box sx={{ position: "absolute", width: '20px', height: '20px', border: '1px solid black', bottom: '2%', left: '2%', borderRadius: '50%' }}>
                                                <DeleteOutline onClick={() => removeFile(item[0])} fontSize='10px' />
                                            </Box>
                                        </Box>

                                    </Tooltip>
                                ) : null}
                            </Stack>

                        </Box>
                        <Button variant='outlined' onClick={() => { formik.handleSubmit() }} fullWidth sx={{ my: 1, backgroundColor: theme => theme.palette.background.paper, '&:hover': { backgroundColor: theme => theme.palette.background.paper }, color: '#fb5d2e', boxShadow: 'none', fontWeight: 'normal', borderRadius: '5px', fontSize: '14px', border: '1px solid #dddde7' }}>Submit Ticket</Button>

                    </Box>}
                    {(!isSubmitted && loadingScore) && <Box component={Paper} elevation={5} sx={{ width: '100%', margin: '0 auto', backgroundColor: 'white', borderRadius: 2, px: 1, py: 2 }}>
                        <Box display="flex" alignItems="center" justifyContent="center" sx={{ m: 2 }}>
                            <CircularProgress sx={{ mx: 1 }} />
                            Creating Ticket...
                        </Box>
                        <Box display="flex" alignContent="center" alignItems='center' justifyContent="center" flexDirection="column" sx={{ m: 1, width: '100%' }}>
                            {fileProgress}
                        </Box>
                    </Box>}
                    {isSubmitted && <Box component={Paper} elevation={5} sx={{ width: '100%', margin: '0 auto', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'white', borderRadius: 2, p: 1 }}>
                        <Drafts sx={{ my: 2, fontSize: '60px', color: setColor?.main }} />
                        <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: '#fb5d2e', my: 1 }}>
                            Thank you, your ticket has been received.
                        </Typography>
                        <Button variant='contained' onClick={() => { handleClose() }} fullWidth sx={{ my: 1, backgroundColor: 'white', '&:hover': { backgroundColor: 'white' }, color: '#fb5d2e', boxShadow: 'none', fontWeight: 'normal', borderRadius: '5px', fontSize: '14px', border: '1px solid #dddde7' }}>Close</Button>

                    </Box>}

                </Card>
            </Box>}

        </>
    )
}
