import React, { useState, useReducer, } from 'react';
// NPM IMPORTS
import axios from 'axios';
import humps from 'humps';
import moment from 'moment';
import { API } from "../backend";
import { viewCoachingSessionAPI, viewAgentReportAPI } from '../_apis_/ai-Api/coachingApi';






export const AIContext = React.createContext()

const handlers = {
    GET_GROUP_DD: (state, action) => {

        const groupDD = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            groupDD
        };
    },
    GET_TEAM_DD: (state, action) => {

        const teamDD = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            teamDD
        };
    },
    GET_AGENT_DD: (state, action) => {

        const agentDD = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            agentDD
        };
    },
    GET_COACH_DD: (state, action) => {

        const coachDD = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            coachDD
        };
    },
    GET_COACHING_AREA_DD: (state, action) => {

        const coachingAreaDD = action.payload;

        return {
            ...state,
            isAuthenticated: true,
            coachingAreaDD
        };
    },
};
const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

export default function AIContextProviders({ children }) {
    const initialState = {
        // userLevels: [],
        groupDD: [],
        teamDD: [],
        agentDD: [],
        coachingAreaDD: [],
        coachDD: [],
        // Teams: [],
        // allUsers: []
    }
    const [state, dispatch] = useReducer(reducer, initialState);
    // STATES
    // COACHING

    const [selectedGroup, setSelectedGroup] = useState('');
    const [selectedTeam, setSelectedTeam] = useState('');
    const [dateRangeValue, setDateRangeValue] = useState('This Month');
    const [customDate, setCustomDate] = useState(false);
    const [selectedFromDate, setSelectedFromDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [selectedToDate, setSelectedToDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
    const [selectedFromWeek, setSelectedFromWeek] = useState();
    const [selectedToWeek, setSelectedToWeek] = useState();
    const [dateRangeValueDrawer, setDateRangeValueDrawer] = useState();
    const [customDateDrawer, setCustomDateDrawer] = useState(false);
    const [selectedFromDateDrawer, setSelectedFromDateDrawer] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [selectedToDateDrawer, setSelectedToDateDrawer] = useState(moment().endOf('month').format('YYYY-MM-DD'));
    const [selectedFromWeekDrawer, setSelectedFromWeekDrawer] = useState();
    const [selectedToWeekDrawer, setSelectedToWeekDrawer] = useState();
    // agent report
    const [agentReportAgent, setAgentReportAgent] = useState([])
    const [agentReportScorecard, setAgentReportScorecard] = useState([])
    // agent Report Table
    const [agentReportTableData, setAgentReportTableData] = useState([])
    const [agentReportTableCount, setAgentReportTableCount] = useState(0)
    const [agentReportTablePage, setAgentReportTablePage] = useState(0)
    const [agentReportTableLoader, setAgentReportTableLoader] = useState(true)
    // create coaching
    const [markedCall, setMarkedCall] = useState([]);
    const [markedScore, setMarkedScore] = useState([]);
    const [coachingArea, setCoachingArea] = useState([])
    // view coaching
    const [coachFilter, setCoachFilter] = useState([])
    const [coachingAreaFilter, setCoachingAreaFilter] = useState([])
    // view coaching table
    const [viewCoachingTableData, setViewCoachingTableData] = useState([])
    const [viewCoachingTableCount, setViewCoachingTableCount] = useState(0)
    const [viewCoachingTablePage, setViewCoachingTablePage] = useState(0)
    const [viewCoachingTableLoader, setViewCoachingTableLoader] = useState(true)
    // ADD COACHING
    const [staffCoachingList, setStaffCoachingList] = useState([])
    const [staffCoachingCount, setStaffCoachingCount] = useState([])
    const [staffCoachingPage, setStaffCoachingPage] = useState(0)
    const [staffCoachingLoader, setStaffCoachingLoader] = useState(true)
    const [selectedAddCoachingGroup, setSelectedAddCoachingGroup] = useState([]);
    const [selectedAddCoachingTeam, setSelectedAddCoachingTeam] = useState([]);
    // 
    const [isAudioDrawerOpen, setIsAudioDrawerOpen] = useState(false);
    const [audioDrawerData, setAudioDrawerData] = useState();
    // ASSIGNMENT MODULES
    const [numOfEvaluationAgent, setNumOfEvaluationAgent] = useState([]);
    const [numOfEvaluationEvaluator, setNumOfEvaluationEvaluator] = useState([]);
    const [numOfEvaluationAgentCopyValue, setNumOfEvaluationAgentCopyValue] = useState(0);
    const [numOfEvaluationEvaluatorCopyValue, setNumOfEvaluationEvaluatorCopyValue] = useState(0);
    const [assignmentPeriodObj, setAssignmentPeriodObj] = useState({})
    const [assignmentPeriodId, setAssignMentPeriodId] = useState();
    const [numberOfEvaluationsTobeDone, setNumberOfEvaluationsTobeDone] = useState(0);
    const [evaluationsToBeDone, setEvaluationsToBeDone] = useState(0)
    const [activeDatas, setActiveDatas] = useState([]);
    // ANALYZE DATAS
    const [selectedAnalyzeFilterTeam, setSelectedAnalyzeFilterTeam] = useState("");
    const [selectedAnalyzeFilterGroup, setSelectedAnalyzeFilterGroup] = useState("");
    const [selectedAnalyzeFromFilterDate, setSelectedAnalyzeFromFilterDate] = useState(moment().startOf('month').format('YYYY-MM-DD'));
    const [selectedAnalyzeToFilterDate, setSelectedAnalyzeToFilterDate] = useState(moment().endOf('month').format('YYYY-MM-DD'));
    const [selectedAnalyzeDateRange, setSelectedAnalyzeFilterDateRange] = useState();


    const getGroupDropDown = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken) {

            axios.get(`${API}/coaching/coaching_session/filter_groups?from_date=${obj?.fromDate}&to_date=${obj?.toDate}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    // console.log("users activity log-------", res);
                    const { data } = humps.camelizeKeys(res?.data);
                    dispatch({
                        type: 'GET_GROUP_DD',
                        payload: data,
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    const getTeamDropDown = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken) {

            axios.get(`${API}/coaching/coaching_session/filter_teams?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&group=${obj?.group}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    // console.log("users activity log-------", res);
                    const { data } = humps.camelizeKeys(res?.data);
                    dispatch({
                        type: 'GET_TEAM_DD',
                        payload: data,
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    const getAgentDropDown = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken) {

            axios.get(`${API}/coaching/coaching_session/filter_agents?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&group=${obj?.group}&team=${obj?.team}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    // console.log("users activity log-------", res);
                    const { data } = humps.camelizeKeys(res?.data);
                    dispatch({
                        type: 'GET_AGENT_DD',
                        payload: data,
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    const getCoachDropDown = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken) {

            axios.get(`${API}/coaching/coaching_session/filter_coaches?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&group=${obj?.group}&team=${obj?.team}&agent__in=${obj?.agents}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    // console.log("users activity log-------", res);
                    const { data } = humps.camelizeKeys(res?.data);
                    dispatch({
                        type: 'GET_COACH_DD',
                        payload: data,
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }

    const getCoachingAreaDropDown = (obj) => {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken) {

            axios.get(`${API}/coaching/coaching_session/filter_coaching_areas?from_date=${obj?.fromDate}&to_date=${obj?.toDate}&group=${obj?.group}&team=${obj?.team}&agent__in=${obj?.agents}&coach__in=${obj?.coaches}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    // console.log("users activity log-------", res);
                    const { data } = humps.camelizeKeys(res?.data);
                    dispatch({
                        type: 'GET_COACHING_AREA_DD',
                        payload: data,
                    })
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    }


    const fetchStaffList = async (groups, teams, filterName, page) => {
        setStaffCoachingList([])
        setStaffCoachingCount(0)
        setStaffCoachingPage(0)
        setStaffCoachingLoader(true)

        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken) {

            axios.get(`${API}/coaching/coaching_session/staffs_listing?group=${groups}&team=${teams}&page=${page}&search=${filterName}`, {
                headers: {
                    'Authorization': `Token ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then((res) => {
                    // console.log("users activity log-------", res);
                    const { data } = res;
                    setStaffCoachingList(data?.results)
                    setStaffCoachingCount(data?.count)
                    setStaffCoachingPage(page - 1)
                    setStaffCoachingLoader(false)
                })
                .catch((error) => {
                    setStaffCoachingList([])
                    setStaffCoachingCount(0)
                    setStaffCoachingPage(0)
                    setStaffCoachingLoader(false)
                })
        }


    }









    const fetchAgentReportIntialData = async (fd, td, grp, tm, agents, coachs, coachAreas, sortType, pg) => {
        setAgentReportTableData([])
        setAgentReportTableCount(0)
        setAgentReportTableLoader(true)
        try {
            const { data } = await viewAgentReportAPI(fd, td, grp, tm, agents, coachs, coachAreas, sortType, pg)
            // console.log(data)
            setAgentReportTableData(data?.results)
            setAgentReportTableCount(data?.count)
            setAgentReportTableLoader(false)
        } catch {
            setAgentReportTableData([])
            setAgentReportTableCount(0)
            setAgentReportTableLoader(false)
        }
    }

    const fetchViewCoachingSessionIntialData = async (fd, td, grp, tm, agents, coachs, coachAreas, pg) => {
        setViewCoachingTableData([])
        setViewCoachingTableCount(0)
        setViewCoachingTableLoader(true)
        try {
            const { data } = await viewCoachingSessionAPI(fd, td, grp, tm, agents, coachs, coachAreas, pg)
            // console.log(data)
            setViewCoachingTableData(data?.results)
            setViewCoachingTableCount(data?.count)
            setViewCoachingTableLoader(false)
        } catch {
            setViewCoachingTableData([])
            setViewCoachingTableCount(0)
            setViewCoachingTableLoader(false)
        }
    }





    const downloadAgentReportXls = async (fd, td, grp, tm, agents, coachs, coachAreas, pg) => {
        const accessToken = window.localStorage.getItem('accessToken');
        // analytic_staff/question_by_staff_question_details?from_date=2022-06-10&to_date=2022-12-13&filter_by=created_at&score_card=86&question_id=663&staff_id=20&answer_id=1570
        // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
        const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
        const config = {
            method: 'GET',
            url: `${API}/coaching/agent_report/listing?from_date=${fd}&to_date=${td}&group=${grp}&team=${tm}&agent__in=${agents}&coach__in=${coachs}&coaching_areas__in=${coachAreas}&page=${pg || 1}&export=true`,
            responseType: 'arraybuffer',
            headers
        };

        try {

            const response = await axios(config);

            const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

            const outputFilename = `Agent Report Xls - ${currentDateTime}.xlsx`;

            // If you want to download file automatically using link attribute.
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            // OR you can save/write file locally.
            // fs.writeFileSync(outputFilename, response.data);
        } catch (error) {
            console.log('agent report download error')
        }
    };


    const downloadCoachingSessionReportXls = async (fd, td, grp, tm, agents, coachs, coachAreas, pg) => {
        const accessToken = window.localStorage.getItem('accessToken');
        // analytic_staff/question_by_staff_question_details?from_date=2022-06-10&to_date=2022-12-13&filter_by=created_at&score_card=86&question_id=663&staff_id=20&answer_id=1570
        // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
        const headers = { Authorization: `Token ${accessToken}`, 'Content-Type': 'blob' };
        const config = {
            method: 'GET',
            url: `${API}/coaching/coaching_session?from_date=${fd}&to_date=${td}&group=${grp}&team=${tm}&agent__in=${agents}&coach__in=${coachs}&coaching_areas__in=${coachAreas}&page=${pg || 1}&export=true`,
            responseType: 'arraybuffer',
            headers
        };

        try {

            const response = await axios(config);

            const currentDateTime = moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a');

            const outputFilename = `Coaching Session Xls - ${currentDateTime}.xlsx`;

            // If you want to download file automatically using link attribute.
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            link.remove();
            // OR you can save/write file locally.
            // fs.writeFileSync(outputFilename, response.data);
        } catch (error) {
            console.log('coaching session download error')
        }
    };










    return (
        <AIContext.Provider value={{
            groupDD: state.groupDD,
            getGroupDropDown,
            teamDD: state.teamDD,
            getTeamDropDown,
            agentDD: state.agentDD,
            coachDD: state.coachDD,
            coachingAreaDD: state.coachingAreaDD,
            getCoachingAreaDropDown,
            getCoachDropDown,
            getAgentDropDown,
            selectedGroup, setSelectedGroup,
            selectedTeam, setSelectedTeam,
            customDate, setCustomDate,
            dateRangeValue, setDateRangeValue,
            selectedFromDate, setSelectedFromDate,
            selectedToDate, setSelectedToDate,
            selectedFromWeek, setSelectedFromWeek,
            selectedToWeek, setSelectedToWeek,
            agentReportAgent, setAgentReportAgent,
            agentReportScorecard, setAgentReportScorecard,
            markedCall, setMarkedCall,
            markedScore, setMarkedScore,
            coachingArea, setCoachingArea,
            coachFilter, setCoachFilter,
            coachingAreaFilter, setCoachingAreaFilter,
            fetchViewCoachingSessionIntialData,
            viewCoachingTableData, setViewCoachingTableData,
            viewCoachingTableCount, setViewCoachingTableCount,
            viewCoachingTableLoader, setViewCoachingTableLoader,
            viewCoachingTablePage, setViewCoachingTablePage,
            agentReportTableData, setAgentReportTableData,
            agentReportTableCount, setAgentReportTableCount,
            agentReportTablePage, setAgentReportTablePage,
            agentReportTableLoader, setAgentReportTableLoader,
            fetchAgentReportIntialData,
            downloadAgentReportXls,
            downloadCoachingSessionReportXls,
            fetchStaffList,
            staffCoachingList, setStaffCoachingList,
            staffCoachingCount, setStaffCoachingCount,
            staffCoachingPage, setStaffCoachingPage,
            selectedAddCoachingGroup, setSelectedAddCoachingGroup,
            selectedAddCoachingTeam, setSelectedAddCoachingTeam,
            staffCoachingLoader, setStaffCoachingLoader,
            dateRangeValueDrawer, setDateRangeValueDrawer,
            customDateDrawer, setCustomDateDrawer,
            selectedFromDateDrawer, setSelectedFromDateDrawer,
            selectedToDateDrawer, setSelectedToDateDrawer,
            selectedFromWeekDrawer, setSelectedFromWeekDrawer,
            selectedToWeekDrawer, setSelectedToWeekDrawer,
            isAudioDrawerOpen, setIsAudioDrawerOpen,
            audioDrawerData, setAudioDrawerData,
            // ASSIGNMENT STATES
            numOfEvaluationAgent, setNumOfEvaluationAgent,
            numOfEvaluationEvaluator, setNumOfEvaluationEvaluator,
            numOfEvaluationAgentCopyValue, setNumOfEvaluationAgentCopyValue,
            numOfEvaluationEvaluatorCopyValue, setNumOfEvaluationEvaluatorCopyValue,
            assignmentPeriodObj, setAssignmentPeriodObj,
            assignmentPeriodId, setAssignMentPeriodId,
            numberOfEvaluationsTobeDone, setNumberOfEvaluationsTobeDone,
            evaluationsToBeDone, setEvaluationsToBeDone,
            activeDatas, setActiveDatas,
            // ANALYZE STATES
            selectedAnalyzeFilterTeam, setSelectedAnalyzeFilterTeam,
            selectedAnalyzeFilterGroup, setSelectedAnalyzeFilterGroup,
            selectedAnalyzeFromFilterDate, setSelectedAnalyzeFromFilterDate,
            selectedAnalyzeToFilterDate, setSelectedAnalyzeToFilterDate,
            selectedAnalyzeDateRange, setSelectedAnalyzeFilterDateRange
        }}>
            {children}
        </AIContext.Provider>
    )
}